import * as moment from 'moment';
import { Progress } from '../progress';

export interface IBackendStudent {
  id?: number;

  first_name?: string;
  last_name?: string;
  student_name?: string;
  display_name?: string;
  user_name?: string;
  password?: string;
  performance?: number | null;
  progress?: Progress[];
  is_active?: number;
  type?: string;
}

export interface IStudent {
  id?: number;

  firstName?: string;
  lastName?: string;
  userName?: string;
  displayName?: string;
  password?: string;
  performance?: number | null;
  progress?: Progress[];
  isActive?: number;
  type?: string;
}

export class Student implements IStudent {
  static readonly clean = Object.freeze(new Student());
  id = 0;

  firstName = '';
  lastName = '';
  userName = '';
  displayName = '';
  password = '';
  performance = 0;
  progress?: Progress[];
  isActive = 1;
  type = '';

  createdAt: Date;
  updatedAt: Date;


  parse(student: IBackendStudent) {
    this.id = Number(student.id || Student.clean.id);

    this.firstName = String(student.first_name || student.student_name || Student.clean.firstName);
    this.lastName = String(student.last_name || Student.clean.lastName);
    this.userName = String(student.user_name || Student.clean.userName);
    this.displayName = String(student.display_name || Student.clean.displayName);
    this.password = String(student.password || Student.clean.password);
    // this.performance = Number(student.performance || Student.clean.performance);
    this.performance = student.performance || Student.clean.performance || null;
    // ^^^^^^^^^^^^^^^^^^^^^^ Using null to add grey status ^^^^^^^^^^^^^^^^^^^^
    if (student.progress) {
      this.progress = (student.progress || Student.clean.progress).map(progress => new Progress().parse(progress));
    }
    this.isActive = Number(student.is_active || Student.clean.isActive);
    this.type = String(student.type || Student.clean.type);

    return this;
  }

  set(student: IStudent) {
    Object.entries(this).forEach(([key]) => {
      this[key] = student[key] || this[key];
    });

    return this;
  }

  clone() {
    return new Student().set(this);
  }

  clear() {
    Object.entries(this).forEach(([key]) => {
      this[key] = Student.clean[key];
    });

    return this;
  }
}
