import { SnackbarService } from './snackbar/snackbar.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { SpinnerComponent } from './components/spinner/spinner.component';
import { ToastComponent } from './components/toast/toast/toast.component';


/** Modules */
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { StatusPercentagePipe } from '../pipes/status-percentage.pipe';

const components = [SpinnerComponent, ToastComponent, StatusPercentagePipe];

@NgModule({

  declarations: [ ...components ],
  imports: [
    CommonModule,
    MatMenuModule,
    MatSnackBarModule,
    TranslateModule,
    MatSlideToggleModule,
  ],
  entryComponents: [
    ToastComponent,
  ],
  exports: [ ...components ],
  providers: [SnackbarService]
})
export class ShareModule { }
