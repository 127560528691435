import * as moment from 'moment';

export interface IBackendMatchDate {
  id?: number;

  match_date?: string;
  session_hash?: string;
  group_id?: number;
  content_id?: number;
  axis?: string;
  lesson?: string;
  school_year?: number;
}

export interface IMatchDate {
  id?: number;

  createdAt?: string;
  sessionHash?: string;
  groupId?: number;
  contentId?: number;
  axis?: string;
  lesson?: string;
  schoolYear?: number;
}

export class MatchDate implements IMatchDate {
  static readonly clean = Object.freeze(new MatchDate());
  id = 0;

  createdAt: string;
  sessionHash = '';
  groupId = 0;
  contentId = 0;
  axis = '';
  lesson = '';
  schoolYear = 0;


  parse(matchDate: IBackendMatchDate) {
    this.id = Number(matchDate.id || MatchDate.clean.id);

    this.sessionHash = String(matchDate.session_hash || MatchDate.clean.sessionHash);
    this.createdAt = matchDate.match_date || MatchDate.clean.createdAt;
    this.groupId = Number(matchDate.group_id || MatchDate.clean.groupId);
    this.contentId = Number(matchDate.content_id || MatchDate.clean.contentId);
    this.axis = String(matchDate.axis || MatchDate.clean.axis);
    this.lesson = String(matchDate.lesson || MatchDate.clean.lesson);
    this.schoolYear = Number(matchDate.school_year || MatchDate.clean.schoolYear);
    // this.createdAt = moment(createdAt ? moment.utc(createdAt).toDate() : null).format('D-MM-YYYY, h:mm');


    return this;
  }

  set(matchDate: IMatchDate) {
    Object.entries(this).forEach(([key]) => {
      this[key] = matchDate[key] || this[key];
    });

    return this;
  }

  clone() {
    return new MatchDate().set(this);
  }

  clear() {
    Object.entries(this).forEach(([key]) => {
      this[key] = MatchDate.clean[key];
    });

    return this;
  }
}
