import { Group } from './../../../../../../_core/models/group/group';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Student, Progress } from 'src/app/_core/models';

@Injectable({
  providedIn: 'root'
})
export class PerformanceDataService {
  selectAxe$ = new BehaviorSubject<string>('');
  groupId$ = new BehaviorSubject<Number>(0);
  selectedStudent$ = new BehaviorSubject<Student>(new Student());
  studentLastProgressDate$ = new BehaviorSubject<string>('');
  studentLastProgress$ = new BehaviorSubject<Progress>(new Progress());

  constructor() { }
}
