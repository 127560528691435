import * as moment from 'moment';
import { Student } from '../student';

export interface IBackendMatch {
  id?: number;

  created_at?: string;
  correct_percentage?: number;
  wrong_answers?: number;
  right_answers?: number;
  total_questions?: number;
  student?: Student;
  session_date?: string;
}

export interface IMatch {
  id?: number;

  createdAt?: string;
  sessionHash?: string;
  correctPercentage?: number;
  wrongAnswers?: number;
  rightAnswers?: number;
  totalQuestions?: number;
  student?: Student;
  sessionDate?: string;
}

export class Match implements IMatch {
  static readonly clean = Object.freeze(new Match());
  id = 0;

  createdAt: string;
  correctPercentage = 0;
  wrongAnswers = 0;
  rightAnswers = 0;
  totalQuestions = 0;
  student: Student;
  sessionDate = '';


  parse(match: IBackendMatch) {
    this.id = Number(match.id || Match.clean.id);

    this.correctPercentage = Number(match.correct_percentage || Match.clean.correctPercentage);
    this.wrongAnswers = Number(match.wrong_answers || Match.clean.wrongAnswers);
    this.rightAnswers = Number(match.right_answers || Match.clean.rightAnswers);
    this.totalQuestions = Number(match.total_questions || Match.clean.totalQuestions);
    this.student = new Student().parse(match.student || Match.clean.student);
    const createdAt = match.created_at || Match.clean.createdAt;
    this.createdAt = moment(createdAt ? moment.utc(createdAt).toDate() : null).format('D-MM-YYYY, h:mm');
    this.sessionDate = String(match.session_date || Match.clean.sessionDate);

    return this;
  }

  set(match: IMatch) {
    Object.entries(this).forEach(([key]) => {
      this[key] = match[key] || this[key];
    });

    return this;
  }

  clone() {
    return new Match().set(this);
  }

  clear() {
    Object.entries(this).forEach(([key]) => {
      this[key] = Match.clean[key];
    });

    return this;
  }
}
