export interface IBackendTopic {
    axis?: string;
    lesson?: string;
    school_year: number;
    sessions: number;
    students: number;
    subject?: string;
    title?: string;
    group?: string;
  }

  export interface ITopic {
    axis?: string;
    lesson?: string;
    schoolYear: number;
    sessions: number;
    students: number;
    subject?: string;
    title?: string;
    group?: string;
  }

  export class Topic implements ITopic {
    static readonly clean = Object.freeze(new Topic());

    axis = '';
    lesson = '';
    schoolYear = 0;
    sessions = 0;
    students = 0;
    subject = '';
    title = '';
    group = '';

    parse(topic: IBackendTopic) {
      this.axis = String(topic.axis || Topic.clean.axis);
      this.lesson = String(topic.lesson || Topic.clean.lesson);
      this.schoolYear = Number(topic.school_year || Topic.clean.schoolYear);
      this.sessions = Number(topic.sessions || Topic.clean.sessions);
      this.students = Number(topic.students || Topic.clean.students);
      this.subject = String(topic.subject || Topic.clean.subject);
      this.title = String(topic.title || Topic.clean.title);
      this.group = String(topic.group || Topic.clean.group);

      return this;
    }

    set(topic: ITopic) {
      Object.entries(this).forEach(([key]) => {
        this[key] = topic[key] || this[key];
      });

      return this;
    }

    clone() {
      return new Topic().set(this);
    }

    clear() {
      Object.entries(this).forEach(([key]) => {
        this[key] = Topic.clean[key];
      });

      return this;
    }
  }
