import * as moment from 'moment';

export interface IBackendContent {
    id?: number;
    created_at?: Date;
    updated_at?: Date;
    axis?: string;
    lesson?: string;
    school_year?: number;
    subject?: string;
    title?: string;
}

export interface IContent {
    id?: number;
    createdAt?: Date;
    updatedAt?: Date;
    axis?: string;
    lesson?: string;
    schoolYear?: number;
    subject?: string;
    title?: string;
}

export class Content implements IContent {
    static readonly clean = Object.freeze(new Content());
    id = 0;
    createdAt?: Date;
    updatedAt?: Date;
    axis = '';
    lesson = '';
    schoolYear = 0;
    subject = '';
    title = '';

    parse(obj: IBackendContent) {
        this.id = Number(obj.id || Content.clean.id);
        this.createdAt = obj.created_at ? moment.utc(obj.created_at).toDate() : Content.clean.createdAt;
        this.updatedAt = obj.updated_at ? moment.utc(obj.updated_at).toDate() : Content.clean.updatedAt;
        this.axis = String(obj.axis || Content.clean.axis);
        this.lesson = String(obj.lesson || Content.clean.lesson);
        this.schoolYear = Number(obj.school_year || Content.clean.schoolYear);
        this.subject = String(obj.subject || Content.clean.subject);
        this.title = String(obj.title || Content.clean.title);

        return this;
    }

    set(obj: IContent) {
        Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

        return this;
    }

    clear() {
        Object.entries(this).forEach(([key]) => {
            this[key] = Content.clean[key];
        });

        return this;
    }

    clone() {
        return new Content().set(this);
    }
}
