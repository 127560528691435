export interface IBackendLesson {
    id?: number;
    lesson?: string;
}

export interface ILesson {
    id?: number;
    lesson?: string;
}

export class Lesson implements ILesson {
    static readonly clean = Object.freeze(new Lesson());
    id = 0;
    lesson = '';

    parse(obj: IBackendLesson) {
        this.id = Number(obj.id || Lesson.clean.id);
        this.lesson = String(obj.lesson || Lesson.clean.lesson);

        return this;
    }

    set(obj: ILesson) {
        Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

        return this;
    }

    clear() {
        Object.entries(this).forEach(([key]) => {
            this[key] = Lesson.clean[key];
        });

        return this;
    }

    clone() {
        return new Lesson().set(this);
    }
}
