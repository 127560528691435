import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import 'hammerjs';
import '@angular/animations';


import { AppComponent } from './app.component';
import { Routes, RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSliderModule } from '@angular/material/slider';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthenticationModule } from './_core/modules/authentication/authentication.module';
import { AuthenticationGuard } from './_core/modules/authentication/authentication.guard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReportModalComponent } from './wrapper-page/modules/groups/components/report-modal/report-modal.component';
import { HelpModalComponent } from './wrapper-page/modules/groups/components/help-modal/help-modal.component';
// tslint:disable-next-line: max-line-length
import { AssignMissionModalComponent } from './wrapper-page/modules/groups/components/assign-mission-modal/assign-mission-modal.component';
import { AddGroupModalComponent } from './wrapper-page/modules/groups/components/add-group-modal/add-group-modal.component';
import { EditProfileModalComponent } from './wrapper-page/modules/groups/components/edit-profile-modal/edit-profile-modal.component';
// tslint:disable-next-line: max-line-length
import { EditDisplayNameModalComponent } from './wrapper-page/modules/groups/components/edit-display-name-modal/edit-display-name-modal.component';
import { EditDisplayIsActiveComponent } from './wrapper-page/modules/groups/components/edit-display-isactive-modal/edit-display-isactive-modal.component';
import { PasswordModalComponent } from './wrapper-page/modules/groups/components/password-modal/password-modal.component';
import { ShareModule } from './_core/modules/share.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

import { MinuteSecondsPipe } from './_core/pipes/minute-seconds.pipe';
import { StatusPercentagePipe } from './_core/pipes/status-percentage.pipe';


const routes: Routes = [
  { path: '',  loadChildren: './wrapper-page/wrapper-page.module#WrapperPageModule', canActivate: [AuthenticationGuard], },
  { path: 'login',  loadChildren: './login-page/login-page.module#LoginPageModule' },
  { path: '**', redirectTo: 'login' }
];

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    AppComponent,
    ReportModalComponent,
    HelpModalComponent,
    AssignMissionModalComponent,
    AddGroupModalComponent,
    EditProfileModalComponent,
    EditDisplayNameModalComponent,
    EditDisplayIsActiveComponent,
    PasswordModalComponent,
    MinuteSecondsPipe,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    HttpClientModule,
    AuthenticationModule,
    BrowserAnimationsModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    ShareModule,
    MatTableModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatSliderModule,
    MatCheckboxModule,
    MatSelectModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  exports:[
  ],
  providers: [],
  entryComponents: [
    ReportModalComponent,
    HelpModalComponent,
    AssignMissionModalComponent,
    AddGroupModalComponent,
    EditProfileModalComponent,
    EditDisplayNameModalComponent,
    EditDisplayIsActiveComponent,
    PasswordModalComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
