import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/_core/modules/authentication/authentication.service';
import { User } from 'src/app/_core/models';
import { SnackbarService } from 'src/app/_core/modules/snackbar/snackbar.service';
import { UserApiService } from 'src/app/_core/api/user';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-profile-modal',
  templateUrl: './edit-profile-modal.component.html',
  styleUrls: ['./edit-profile-modal.component.scss']
})
export class EditProfileModalComponent implements OnInit {
  editProfileForm = this._formBuilder.group({
    name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    password: [''],
  });
  profileEditedError = false;
  profileEdited = false;
  isBusy = false;
  user = new User();

  constructor(
    private translationService: TranslateService,
    private _formBuilder: FormBuilder,
    private _authService: AuthenticationService,
    public dialogRef: MatDialogRef<EditProfileModalComponent>,
    private _userApiService: UserApiService,
    private _snackbarService: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: ''
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.user = this._authService.user;
      // console.log( 'this.user --> ', this.user );
      this.editProfileForm.setValue({
        name: this.user.fullName,
        email: this.user.email,
        password: ''
      });
    }, );
  }

  closeModal() {
    this.dialogRef.close();
  }

  onSubmit() {
    this._userApiService.updateUser(
      this.user.id,
      this.editProfileForm.get('name').value,
      this.editProfileForm.get('email').value,
      this.editProfileForm.get('password').value,
    )
    .subscribe( response => {
      // console.log( '_userApiService updateUser response --> ', response );
      this._authService.user = response.user;
      this._snackbarService.openSnackBar({
        message: 'USER_INFO_SUCCESFULL_CHANGE',
        panelClass: 'edit-profile--default',
        type: 'default',
      });
      this.closeModal();
    }, ( error ) => {
      console.log( error );
      if ( error.error.error === 'name' ) {
        this._snackbarService.openSnackBar({
          message: 'INVALID_NAME',
          panelClass: 'warning-toast',
          type: 'warning',
        });
      } else if ( error.error.error === 'email' ) {
        this._snackbarService.openSnackBar({
          message: 'INVALID_NAME',
          panelClass: 'warning-toast',
          type: 'warning',
        });
      } else if ( error.error.error === 'PASSWORD_MUST_BE_LONGER' ) {
        this._snackbarService.openSnackBar({
          message: 'PASSWORD_MUST_BE_LONGER',
          panelClass: 'warning-toast',
          type: 'warning',
        });
      } else {
        this._snackbarService.openSnackBar({
          message: 'ERROR_INPUT_INFORMATION_TRY_LATER',
          panelClass: 'warning-toast',
          type: 'warning',
        });
      }
    });
  }

}
