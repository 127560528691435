import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { User, Group, MatchDate, Axis } from '../../models';
import { AuthenticationService } from '../../modules/authentication/authentication.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserApiService {
  reportSelectedGroup$ = new BehaviorSubject<number>(0);
  selectedGroupGrade$ = new BehaviorSubject<number>(0);

  constructor(
    private _http: HttpClient,
    private _sessionService: AuthenticationService
  ) { }

  getUsers(paginated?: boolean, page?: number, search?: string) {
    const params = [
      `paginated=${ Number(paginated || 0) }`,
      `per_page=${ 10 }`,
      `page=${ page || 0 }`,
      `search=${ search || '' }`,
    ].join('&');

    return this._http.get(environment.API_URL + `user/list?` + params)
      .pipe(map((data: any) => ({
        users: data.users.map( (user: User) => new User().parse(user))
      })));
  }

  getUser(userId: number) {
    const params = [
      `id=${ Number(userId || 0) }`,
    ].join('&');

    return this._http.get(environment.API_URL + `user/` + params)
      .pipe(map((data: any) => ({
        users: data
      })));
  }

  logIn(email: string, password: string) {
    const params = {
      email,
      password,
    };

    return this._http.post(environment.API_URL + `user/in`, params)
      .pipe(map((data) => ({
        sessionToken: String(data['session_token']),
        user: new User().parse(data['user'])
      })));
  }

  forgotPassword(email: string) {
    const params = {
      email,
    };
    return this._http.post(environment.API_URL + `user/forgot`, params)
      .pipe(map((data) => ({
        status: String(data['status'])
      })));
  }

  public logout() {
    return this._http.get(environment.API_URL + `user/out`)
      .pipe(map((data) => {
        this._sessionService.clear();
        return true;
      }));
  }

  saveUser(user: User) {
    let url = 'user/save';

    if (user.id !== 0) {
      url = url + '/' + user.id;
    }
    const params = {
      email: user.email,
      password: user.password,
      full_name: user.fullName,
    };

    if (user.password === '') {
      delete params.password;
    }

    return this._http.post(environment.API_URL + url, params)
    .pipe(map((data) => ({
      user: new User().parse(data['user'])
    })));
  }

  updateUser(
    id: number,
    fullName: string,
    email: string,
    password: number) {
    const params = {
      id: id,
      full_name: fullName,
      email: email,
      password: password,
    };
    return this._http.post(environment.API_URL + 'user/update', params)
    .pipe(map((data) => ({
      user: new User().parse(data['user'])
    })));
  }

  deleteUser(userId: number) {
    return this._http.delete(environment.API_URL + 'user/delete/' + userId)
    .pipe(map((data) => ({
      users: data['users'].map(user => new User().parse(user))
    })));
  }

  getModalInfo() {
    return this._http.get(environment.API_URL + `user/group/report`)
    .pipe(map((data: any) => ({
      groups: data.groups.map( (group: Group) => new Group().parse(group)),
      matches: data.matches.map( (matchDate: MatchDate) => new MatchDate().parse(matchDate)),
      axes: data.axis
    })));
  }

}
