import * as moment from 'moment';
import { Content } from '../content';

export interface IBackendProgress {
    id?: number;
    created_at?: Date;
    updated_at?: Date;

    average_answer?: number;
    content?: Content;
    content_id?: number;
    correct_percentage?: number;
    organization_id?: number;
    right_answers?: number;
    session_date?: string;
    session_hash?: string;
    skipped_answers?: number;
    team?: string;
    total_questions?: number;
    total_time?: number;
    wrong_answers?: number;
}

export interface IProgress {
    id?: number;
    createdAt?: Date;
    updatedAt?: Date;

    averageAnswer?: number;
    content?: Content;
    contentId?: number;
    correctPercentage?: number;
    organizationId?: number;
    rightAnswers?: number;
    sessionDate?: string;
    sessionHash?: string;
    skippedAnswers?: number;
    team?: string;
    totalQuestions?: number;
    totalTime?: number;
    wrongAnswers?: number;
}

export class Progress implements IProgress {
    static readonly clean = Object.freeze(new Progress());
    id = 0;
    createdAt?: Date;
    updatedAt?: Date;

    averageAnswer = 0;
    content: Content;
    contentId = 0;
    correctPercentage = 0;
    organizationId = 0;
    rightAnswers = 0;
    sessionDate = '';
    sessionHash = '';
    skippedAnswers = 0;
    team = '';
    totalQuestions = 0;
    totalTime = 0;
    wrongAnswers = 0;

    parse(obj: IBackendProgress) {
        this.id = Number(obj.id || Progress.clean.id);
        this.createdAt = obj.created_at ? moment.utc(obj.created_at).toDate() : Progress.clean.createdAt;
        this.updatedAt = obj.updated_at ? moment.utc(obj.updated_at).toDate() : Progress.clean.updatedAt;

        this.averageAnswer = Number(obj.average_answer || Progress.clean.averageAnswer);
        this.content = new Content().parse(obj.content || Progress.clean.content);
        this.contentId = Number(obj.content_id || Progress.clean.contentId);
        this.correctPercentage = Number(obj.correct_percentage || Progress.clean.correctPercentage);
        this.organizationId = Number(obj.organization_id || Progress.clean.organizationId);
        this.rightAnswers = Number(obj.right_answers || Progress.clean.rightAnswers);
        this.sessionDate = String(obj.session_date || Progress.clean.sessionDate);
        this.sessionHash = String(obj.session_hash || Progress.clean.sessionHash);
        this.skippedAnswers = Number(obj.skipped_answers || Progress.clean.skippedAnswers);
        this.team = String(obj.team || Progress.clean.team);
        this.totalQuestions = Number(obj.total_questions || Progress.clean.totalQuestions);
        this.totalTime = Number(obj.total_time || Progress.clean.totalTime);
        this.wrongAnswers = Number(obj.wrong_answers || Progress.clean.wrongAnswers);

        return this;
    }

    set(obj: IProgress) {
        Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

        return this;
    }

    clear() {
        Object.entries(this).forEach(([key]) => {
            this[key] = Progress.clean[key];
        });

        return this;
    }

    clone() {
        return new Progress().set(this);
    }
}
