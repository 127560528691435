import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationInterceptorService implements HttpInterceptor {

  constructor(private _authService: AuthenticationService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const headers = {};

    if (this._authService.isLogged()) {
      headers['user-id'] = String(this._authService.user.id);
      headers['session-token'] = this._authService.sessionToken;
    }

    return next.handle(req.clone({ setHeaders: headers }));
  }

}
