import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { MatchDate, Student, Match, Topic } from '../../models';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MatchApiService {
  selectedMatchIndex$ = new BehaviorSubject<number>(0);

  constructor(
    private _http: HttpClient,
  ) { }

  getMatches(groupId: number, session: string) {
    const params = [
      `group_id=${ groupId }`,
      `session_hash=${ session }`
    ].join('&');

    return this._http.get(environment.API_URL + `session?` + params)
    .pipe(map((data: any) => ({
      matches: (data.results || []).map((match: Match) => new Match().parse(match)),
      topic: new Topic().parse(data.info)
    })));
  }

  getMatchesSearch(groupId: number, session: string, search: string) {
    const params = [
      `group_id=${ groupId }`,
      `session_hash=${ session }`,
      `search=${ search }`
    ].join('&');

    return this._http.get(environment.API_URL + `session?` + params)
    .pipe(map((data: any) => ({
      matches: (data.results || []).map((match: Match) => new Match().parse(match)),
      topic: new Topic().parse(data.info)
    })));
  }

  getMatchesDate(groupId: number) {
    const params = [
      `per_page=${10}`,
      `page= ${ 1 }`
    ].join('&');

    return this._http.get(environment.API_URL + `user/group/${groupId}/matches?` + params)
    .pipe(map((data: any) => ({
      matches: (data.matches || []).map((match: MatchDate) => new MatchDate().parse(match))
    })));
  }

  getMatchesReport(groupId: number, sessionHash: string, lang: string) {
    const params = [
      `group_id=${groupId}`,
      `session_hash=${sessionHash}`,
      `lang=${lang !== '' ? lang : 'es'}`
    ].join('&');

    return this._http.get(environment.API_URL + `user/match/report?` + params)
    .pipe(map((data: any) => ({
      data: data
    })));
  }

}
