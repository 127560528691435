import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MissionsApiService {

  constructor(
    private _http: HttpClient,
  ) { }

  saveMission(formData: any){
    const params = {
      content_id: formData.content_id,
      level: formData.level,
      start_date: formData.start_date,
      end_date:  formData.end_date,
      exercise_count: formData.exercise_count,
      random_exercises: formData.random_exercises,
      allow_strikes: formData.allow_strikes,
      time: formData.time,
      minimum_percentage: formData.minimum_percentage,
      group_id: formData.group_id,
    };
    return this._http.post(environment.API_URL + `user/mission/${formData.id }`, params)
    .pipe(map((data:any) => ({
      mision: data
    })));
  }


	getMissions(
		group_id: string,
  ) {
		const params = new HttpParams()
		// .set('page', String(page))
    // .set('per_page', String(perPage))
    .set('group_id', String(group_id));

    return this._http.get(environment.API_URL + `user/mission/list`, { params })
    .pipe(map((data: any) => ({
      missions: data
    })));
  }

  getTopics(schoolYear?: number) {
    return this._http.get(environment.API_URL + `contents` +  (schoolYear ? `?school_year=${schoolYear}`  : ''))
    .pipe(map((data: any) => ({
      topics: data.contents
    })));
  }

  getMission(id: number) {
    return this._http.get(environment.API_URL + `user/mission/` + id)
    .pipe(map((data: any) => ({
      mission: data.mission
    })));
	}


}


