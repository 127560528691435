import * as moment from 'moment';

export interface IBackendAxis {
  // id?: number;

  axis?: string;
  session_hash?: string;
  group_id?: number;
  content_ids?: number[];
}

export interface IAxis {
  // id?: number;

  createdAt?: string;
  sessionHash?: string;
  groupId?: number;
  contentIds?: number[];
}

export class Axis implements IAxis {
  static readonly clean = Object.freeze(new Axis());
  // id = 0;

  createdAt: string;
  sessionHash = '';
  groupId = 0;
  contentIds: number[] = [];


  parse(axis: IBackendAxis) {
    // this.id = Number(axis.id || Axis.clean.id);

    this.sessionHash = String(axis.session_hash || Axis.clean.sessionHash);
    this.createdAt = String(axis.axis || Axis.clean.createdAt);
    this.groupId = Number(axis.group_id || Axis.clean.groupId);
    this.contentIds = (axis.content_ids || Axis.clean.contentIds || []).map( contentId => contentId );
    // that's not right ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^


    return this;
  }

  set(axis: IAxis) {
    Object.entries(this).forEach(([key]) => {
      this[key] = axis[key] || this[key];
    });

    return this;
  }

  clone() {
    return new Axis().set(this);
  }

  clear() {
    Object.entries(this).forEach(([key]) => {
      this[key] = Axis.clean[key];
    });

    return this;
  }
}
