import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MissionsApiService } from 'src/app/_core/api/mission/missions-api.service';

import * as moment from 'moment';

moment.locale('es');

@Component({
  selector: 'app-assing-mission-modal',
  templateUrl: './assign-mission-modal.component.html',
  styleUrls: ['./assign-mission-modal.component.scss']
})
export class AssignMissionModalComponent implements OnInit {

  isBusy = false;
  modalTitle = 'ASSIGN_MISSION';
  group: any;
  unlimited_time = false;
  unlimited_exercises = false;
  topicList = [];
  levelSelect = ['Basico', 'Intermedio', 'Avanzando'];
  errorHandler = '';
  missionCreated = false;

  // Input variables
  form = {
    id: 0,
    content_id: '',
    level: 'Basico',
    start_date: moment(new Date()).format('YYYY-MM-DD'),
    end_date:  moment(new Date()).format('YYYY-MM-DD'),
    exercise_count: 1,
    random_exercises: 0,
    allow_strikes: 0,
    time: 30,
    minimum_percentage: 0,
    group_id: 0
  };



  constructor(
    private _missionApiService: MissionsApiService,
    private _activeRoute: ActivatedRoute,
    private _router: Router,
    public dialogRef: MatDialogRef<AssignMissionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ''
    ) {
      this.group = data;
      this.form.group_id = this.group.id;
    }


  ngOnInit() {
    this.getTopics();
  }

  getTopics(){
    this.isBusy = true;
    this._missionApiService.getTopics(this.group.schoolYear).subscribe(response =>{
      response.topics.map((element, index) => {
        this.topicList[index] = element;
      });

      this.form.content_id = this.topicList[0].id;
      this.isBusy = false;
    })
  }

  closeModal() {
    this.dialogRef.close(this.missionCreated);
  }

  onChange(e, inputName){

    if(inputName === 'unlimited_exercises' ){
      this.unlimited_exercises = e;
      return;
    }

    if(inputName === 'unlimited_time' ){
      this.unlimited_time = e;
      return;
    }

    if (inputName === 'start_date' || inputName === 'end_date' ) {
      this.form[inputName] = moment(e._validSelected).locale('es').format('YYYY-MM-DD');
      return;
    }

    if (typeof e === 'boolean') {
      this.form[inputName] = + e;
      return;
    }

    this.form[inputName] = e;
  }

  onSubmit() {


    if (this.unlimited_time) {this.form.time = 0; }
    if (this.unlimited_exercises) {this.form.exercise_count = 0; }


     if( moment(this.form.end_date).isBefore(this.form.start_date) ){
       this.errorHandler = "ERR_END_IS_BEFORE_START_DATE";
       return;
     }

     this.isBusy = true;

    this._missionApiService.saveMission(this.form).subscribe(response=>{
      this.missionCreated = true;
      this.isBusy = false;
      this.closeModal();
    });

  }


}
