import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/_core/modules/authentication/authentication.service';
import { User } from 'src/app/_core/models';
import { SnackbarService } from 'src/app/_core/modules/snackbar/snackbar.service';
import { UserApiService } from 'src/app/_core/api/user';
import { TranslateService } from '@ngx-translate/core';
import { GroupApiService } from 'src/app/_core/api/group/group-api.service';

@Component({
  selector: 'app-edit-display-isactive-modal',
  templateUrl: './edit-display-isactive-modal.component.html',
  styleUrls: ['./edit-display-isactive-modal.component.scss']
})
export class EditDisplayIsActiveComponent implements OnInit {
  editDisplayNameForm = this._formBuilder.group({
    displayName: ['', Validators.required],
    // email: ['', [Validators.required, Validators.email]],
    // password: [''],
  });
  displayNameEditedError = false;
  displayNameEdited = false;
  isBusy = false;
  hideStatus = 'confirming';
  student: any;

  constructor(
    private translationService: TranslateService,
    private _formBuilder: FormBuilder,
    private _authService: AuthenticationService,
    public dialogRef: MatDialogRef<EditDisplayIsActiveComponent>,
    private _userApiService: UserApiService,
    private _snackbarService: SnackbarService,
    private _groupApiService: GroupApiService,
    @Inject(MAT_DIALOG_DATA) public data: ''
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.subscribeToSelectedUser();
      this.editDisplayNameForm.setValue({
        displayName: this.student.displayName,
      });
    }, );
  }

  closeModal() {
    this.dialogRef.close();
  }

  hideStudent() {
    this.isBusy = true;
    this._groupApiService.hideStudent(this.student.id)
    .subscribe( response => {
      this.isBusy = false;
      this.hideStatus = 'hidden';
      this._groupApiService.displayStudentUpdated$.next(true);
      this._snackbarService.openSnackBar({
        message: 'SUCCESFULL_UPDATE',
        panelClass: 'edit-display-name--default',
        type: 'default',
      });
    }, ( error ) => {
      console.log( error );
      this.isBusy = false;
      this.hideStatus = 'error';
    });
  }

  hideCanceled() {
    this.hideStatus = '';
  }

  subscribeToSelectedUser() {
    this._groupApiService.selectedDisplayStudent$
    .subscribe( response => {
      this.student = response;
    });
  }

}
