import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserApiService } from 'src/app/_core/api/user';
import { Group, MatchDate } from 'src/app/_core/models';
import { MatchApiService } from 'src/app/_core/api/match/match-api.service';
import { GroupApiService } from 'src/app/_core/api/group/group-api.service';
import { PerformanceDataService } from '../../pages/performance-page/data-services/performance-data-service.service';
import { environment } from './../../../../../../environments/environment';
import { LanguageService } from 'src/app/_core/language/language.service';
import { SnackbarService } from 'src/app/_core/modules/snackbar/snackbar.service';


@Component({
  selector: 'app-report-modal',
  templateUrl: './report-modal.component.html',
  styleUrls: ['./report-modal.component.scss']
})
export class ReportModalComponent implements OnInit {
  reportType: 'Group Matches Report' | 'Student Report' | 'Group Performance Report' = 'Group Matches Report';
  isBusy = false;
  location = '';
  axes: string[] = [];
  groups: Group[] = [];
  matches: MatchDate[];
  response: any;
  selectedGroupId = 0;
  selectedAxis = '';
  selectedAxisIndex = 0;
  selectedDate = '';
  selectedSessionHash = '';
  selectedFormattedDate = '';
  selectedMonth = '';
  selectedYear = '';
  selectedYearNumber = 0;
  selectedMatchIndex = 0;
  currentDate: Date = new Date();
  months = [
    'JANUARY',
    'FEBRUARY',
    'MARCH',
    'APRIL',
    'MAY',
    'JUNE',
    'JULY',
    'AUGUST',
    'SEPTEMBER',
    'OCTOBER',
    'NOVEMBER',
    'DECEMBER'
  ];
  apiUrl = environment.API_URL;
  lessons = [];
  selectedLesson = '';
  schoolYear = 'FIRST_GRADE';
  schoolYearNumber = 1;
  schoolYears = [
    'FIRST_GRADE',
    'SECOND_GRADE',
    'THIRD_GRADE',
    'FOURTH_GRADE',
    'FIFTH_GRADE',
    'SIXTH_GRADE'
  ];
  downloading = false;
  filter = 'last_match';
  filters = [
    { text: 'LAST_MATCH', value: 'last_match' },
    { text: 'ALL_MATCHES', value: 'all' },
  ];
  groupGrade = 0;
  groupGradeOptions = [
    {text: '0', value: 0}, // 2 for second grade, etc.
    {text: 'OTHER_GRADES', value: 0},
  ];


  constructor(
    private languageService: LanguageService,
    public dialogRef: MatDialogRef<ReportModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: '',
    private _router: Router,
    private _location: Location,
    private _userApiService: UserApiService,
    private _matchApiService: MatchApiService,
    private _groupApiService: GroupApiService,
    private _performanceDataService: PerformanceDataService,
    private _snackbarService: SnackbarService,
  ) { }


  ngOnInit() {
    this.isBusy = true;
    this.subscribeToSelectedMatchIndex();
    this.subscribeToSelectedGrade();
    this.subscribeToSelectedGroupId();
    this.subscribeToSelectedDate();
    this.subscribeToSelectAxe();
    // const d = new Date();
    // const n = d.getMonth();
    // this.selectedMonth = String(n + 1);
    this.subscribeToSelectedMonth();
    this.subscribeToSelectedYear();
    this.location = this._location.path();

    if (this.location.indexOf('grupos/partidas') !== -1) { // Group Matches Report
      this.reportType = 'Group Matches Report';
    } else if (this.location.indexOf('/alumno') !== -1) { // Student Report
      this.reportType = 'Student Report';
    } else if (this.location.indexOf('grupos/desempeno') !== -1) { // Group Performance Report
      this.reportType = 'Group Performance Report';
    }
    this.getGroupMatchesReportInfo();
    this.onSelectedMonthChange();
    this.subscribeToStudentLessons();
    this.subscribeToSelectedLesson();
    this.getSchoolYear();
    this.getSchoolYearNumber();
  }

  getGroupMatchesReportInfo() {
    this._userApiService.getModalInfo()
    .subscribe( response => {
      this.response = response;
      this.groups = response.groups;
      this.axes = response.axes;
      // console.log( 'this.axes --> ', this.axes );
      this.selectedAxis = this.axes[0]['axis'];
      this.selectedAxisIndex = 0;
      this.filterMatches();
      setTimeout(() => {
        if (this.matches[this.selectedMatchIndex] !== undefined) {
          this.selectedDate = this.matches[this.selectedMatchIndex].createdAt;
          this.getSelectedSessionHash();
        }
        this.isBusy = false;
      }, 200);
    }, ( error ) => {
      console.log( error );
      this.isBusy = false;
    });
  }

  closeModal() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.closeModal();
  }

  getSelectedSessionHash() {
    this.matches.forEach((match, index) => {
      if ( match.createdAt === this.selectedDate ) {
        this.selectedSessionHash = match.sessionHash;
      }
    });
  }

  // On select changes ---------------------------------------------------------

  onGroupChange() {
    this.selectedMatchIndex = 0;
    this.filterMatches();
  }

  onAxisChange() {
    this.axes.forEach( (axis, index) => {
      if (axis['axis'] === this.selectedAxis) {
        this.selectedAxisIndex = index;
      }
    });
    this.selectedMatchIndex = 0;
    this.filterMatches();
  }

  onDateChange() {
    this.getSelectedSessionHash();
  }

  filterMatches() {
    this.matches = [];
    this.response.matches.forEach(match => {
      this.axes[this.selectedAxisIndex]['content_ids'].forEach(contentId => {
        if ( match.contentId === contentId && match.groupId === this.selectedGroupId ) {
          this.matches.push(match);
        }
      });
    });
    // console.log( 'this.matches json --> ', JSON.parse(JSON.stringify(this.matches)) );

    if ( this.matches.length > 0 ) {
      // change language!
      const language = localStorage.getItem('userLangStored');
      // console.log( 'language --> ', language );
      if ( language && (language === 'es' || language === 'de' ) ) {
        // this.matches have their date in ENGLISH.
        // So if user language is different, they must be changed.
        this.matches.forEach( match => {
          //                                         English                           Spanish     German
          match.createdAt = match.createdAt.replace('January ',   language === 'es' ? 'Enero '      : 'Januar '    );
          match.createdAt = match.createdAt.replace('February ',  language === 'es' ? 'Febrero '    : 'Februar '   );
          match.createdAt = match.createdAt.replace('March ',     language === 'es' ? 'Marzo '      : 'März '      );
          match.createdAt = match.createdAt.replace('April ',     language === 'es' ? 'Abril '      : 'April '     );
          match.createdAt = match.createdAt.replace('May ',       language === 'es' ? 'Mayo '       : 'Mai '       );
          match.createdAt = match.createdAt.replace('June ',      language === 'es' ? 'Junio '      : 'Juni '      );
          match.createdAt = match.createdAt.replace('July ',      language === 'es' ? 'Julio '      : 'Juli '      );
          match.createdAt = match.createdAt.replace('August ',    language === 'es' ? 'Agosto '     : 'August '    );
          match.createdAt = match.createdAt.replace('September ', language === 'es' ? 'Septiembre ' : 'September ' );
          match.createdAt = match.createdAt.replace('October ',   language === 'es' ? 'Octubre '    : 'Oktober '   );
          match.createdAt = match.createdAt.replace('November ',  language === 'es' ? 'Noviembre '  : 'November '  );
          match.createdAt = match.createdAt.replace('December ',  language === 'es' ? 'Diciembre '  : 'Dezember '  );
        });
      }

      this.selectedDate = this.matches[this.selectedMatchIndex].createdAt;
    }
    // console.log( 'this.matches --> ', this.matches );
  }

  onSelectedMonthChange() {
    this.formatDate();
  }

  onSelectedYearChange() {
    this.selectedYearNumber = Number(this.selectedYear);
    this.formatDate();
  }

  onSelectedLessonChange() {
  }

  formatDate() {
    this.selectedFormattedDate = this.selectedYear + '-' + this.selectedMonth;
  }

  onLessonChange() {
  }

  onSchoolYearChange() {
    this.getSchoolYearNumber();
    this.getSchoolYear();
  }

  getSchoolYear() {
    this.schoolYearNumber = this._groupApiService.selectedSchoolYear$.value;
    this.schoolYears.forEach( (year, index) => {
      if (index === this.schoolYearNumber - 1) {
        this.schoolYear = year;
      }
    });
  }

  getSchoolYearNumber() {
    this.schoolYears.forEach( (year, index) => {
      if (year === this.schoolYear) {
        this.schoolYearNumber = index + 1;
        this._groupApiService.selectedSchoolYear$.next(this.schoolYearNumber);
      }
    });
  }

  onFilterChange() {
    // console.log( 'onFilterChange this.filter --> ', this.filter );
  }

  onGroupGradeChange() {
    // console.log( 'onGroupGradeChange this.groupGrade --> ', this.groupGrade );
  }

  // Subscriptions -------------------------------------------------------------

  subscribeToSelectedGrade() {
    this._userApiService.selectedGroupGrade$
    // .takeUntil(this.destroyed$)
    .subscribe( gradeNumber => {
      this.groupGrade = gradeNumber;
      // console.log( 'this.groupGrade --> ', this.groupGrade );
      this.groupGradeOptions = [
        {text: String(this.groupGrade), value: this.groupGrade},
        {text: 'OTHER_GRADES', value: 0},
      ];
    });
  }

  subscribeToSelectedGroupId() {
    this._userApiService.reportSelectedGroup$
    .subscribe( response => {
      this.selectedGroupId = response;
    }, ( error ) => {
      console.log( error );
    });
  }

  subscribeToSelectedDate() {
    this._groupApiService.selectedFormattedDate$
    .subscribe( response => {
      this.selectedFormattedDate = response;
    }, ( error ) => {
      console.log( error );
    });
  }

  subscribeToSelectAxe() {
    this._performanceDataService.selectAxe$
    .subscribe( response => {
      setTimeout(() => {
        this.selectedAxis = response;
        // console.log( 'this.selectedAxis --> ', this.selectedAxis );
        this.onAxisChange();
      }, 2000);
    }, ( error ) => {
      console.log( error );
    });
  }

  subscribeToSelectedYear() {
    this._groupApiService.selectedYear$
    .subscribe( response => {
      this.selectedYear = response === '' ? String(this.currentDate.getFullYear()) : response;
      this.selectedYearNumber = Number(this.selectedYear);
    }, ( error ) => {
      console.log( error );
    });
  }

  subscribeToSelectedMonth() {
    this._groupApiService.selectedMonth$
    .subscribe( response => {
      this.selectedMonth = response;
    }, ( error ) => {
      console.log( error );
    });
  }

  subscribeToSelectedMatchIndex() {
    this._matchApiService.selectedMatchIndex$
    .subscribe( response => {
      this.selectedMatchIndex = response;
    }, error => {
      console.log( error );
    });
  }

  subscribeToStudentLessons() {
    this._groupApiService.studentLessons$
    .subscribe( response => {
      this.lessons = response;
    }, ( error ) => {
      console.log( error );
    });
  }

  subscribeToSelectedLesson() {
    this._groupApiService.selectedLesson$
    .subscribe( response => {
      this.selectedLesson = response;
    }, ( error ) => {
      console.log( error );
    });
  }

  // Download reports ---------------------------------------------------------

  downloadGroupMatchesReport() {
    this.downloading = true;
    const lang = localStorage.getItem('userLangStored');
    this._matchApiService.getMatchesReport(this.selectedGroupId, this.selectedSessionHash, lang)
    .subscribe( response => {
      const downloadLink = document.createElement('a');
      downloadLink.href = this.apiUrl.replace('index.php/', '') + response.data['path'];
      downloadLink.target = '_blank';
      downloadLink.click();
      this.downloading = false;
    }, ( error ) => {
      console.log( error );
      if (error.error.message === 'report_not_available') {
        this._snackbarService.openSnackBar({
          message: 'ERR_REPORT_NOT_AVAILABLE',
          panelClass: 'warning-toast',
          type: 'warning',
        });
      } else {
        this._snackbarService.openSnackBar({
          message: 'ERR_TRY_AGAIN',
          panelClass: 'warning-toast',
          type: 'warning',
        });
      }
      this.downloading = false;
    });
  }

  downloadStudentReport() {
    this.downloading = true;
    const lang = localStorage.getItem('userLangStored');
    this._groupApiService.getStudentPerformanceReport(
      this.schoolYearNumber,
      this.selectedLesson,
      this.selectedFormattedDate,
      this.selectedGroupId,
      this._groupApiService.selectedStudentId$.value,
      this.selectedAxis,
      lang
      )
    .subscribe( response => {
      const downloadLink = document.createElement('a');
      downloadLink.href = this.apiUrl.replace('index.php/', '') + response.data['path'];
      downloadLink.target = '_blank';
      downloadLink.click();
      this.downloading = false;
    }, ( error ) => {
      console.log( error );
      if (error.error.message === 'report_not_available') {
        this._snackbarService.openSnackBar({
          message: 'ERR_REPORT_NOT_AVAILABLE',
          panelClass: 'warning-toast',
          type: 'warning',
        });
      } else {
        this._snackbarService.openSnackBar({
          message: 'ERR_TRY_AGAIN',
          panelClass: 'warning-toast',
          type: 'warning',
        });
      }
      this.downloading = false;
    });
  }

  downloadGroupPerformanceReport() {
    this.downloading = true;
    const lang = localStorage.getItem('userLangStored');
    this._groupApiService.getGroupPerformanceReport(this.selectedAxis, this.selectedGroupId, this.filter, lang, this.groupGrade)
    .subscribe( response => {
      const downloadLink = document.createElement('a');
      downloadLink.href = this.apiUrl.replace('index.php/', '') + response.data['path'];
      downloadLink.target = '_blank';
      downloadLink.click();
      this.downloading = false;
    }, ( error ) => {
      console.log( error );
      if (error.error.message === 'report_not_available') {
        this._snackbarService.openSnackBar({
          message: 'ERR_REPORT_NOT_AVAILABLE',
          panelClass: 'warning-toast',
          type: 'warning',
        });
      } else {
        this._snackbarService.openSnackBar({
          message: 'ERR_TRY_AGAIN',
          panelClass: 'warning-toast',
          type: 'warning',
        });
      }
      this.downloading = false;
    });
  }

}
