import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { GroupApiService } from 'src/app/_core/api/group/group-api.service';
import { Group } from 'src/app/_core/models';
import { LanguageService } from 'src/app/_core/language/language.service';


@Component({
  selector: 'app-add-group-modal',
  templateUrl: './add-group-modal.component.html',
  styleUrls: ['./add-group-modal.component.scss']
})
export class AddGroupModalComponent implements OnInit {
  addGroupForm = this._formBuilder.group({
    alias: ['', Validators.required],
    schoolLevel: ['', Validators.required],
    schoolYear: ['', Validators.required],
    // studentsCount: ['', Validators.required]
  });
  groupSentError = false;
  groupSent = false;
  schoolLevels = [
    'Preescolar',
    'Primaria',
    'Secundaria'
  ];
  schoolLevelDefault = 'Primaria';
  schoolYears = [
    'Primero',
    'Segundo',
    'Tercero',
    'Cuarto',
    'Quinto',
    'Sexto'
  ];
  schoolYearDefault = 'Primero';
  isBusy = false;
  modalTitle = 'ADD_GROUP';
  groupAlias = '';
  groupId =  0;
  userId =  0;
  editingCanDeleteGroup = false;
  deleteStatus = '';
  groups: Group[] = [];


  constructor(
    private languageService: LanguageService,
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddGroupModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _groupApiService: GroupApiService
  ) { }

  ngOnInit() {
    // console.log(this.data);
    this.subscribeToGroups();
    if ( this.data !== 'open Add group' ) {
      this.editingCanDeleteGroup = true;
      this.modalTitle = 'EDIT_GROUP';
      this.groupId = this.data['id'];
      this.userId = this.data['userId'];
      this.addGroupForm.setValue({
        alias: this.data['alias'],
        schoolLevel: this.getCapitalizedString(this.data['schoolLevel']),
        schoolYear: this.getSChoolYearByNumber(),
      });
    } else {
      this.editingCanDeleteGroup = false;
      this.addGroupForm.controls['schoolLevel'].setValue(this.schoolLevelDefault, {onlySelf: true});
      this.addGroupForm.controls['schoolYear'].setValue(this.schoolYearDefault, {onlySelf: true});
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  getCapitalizedString(text: string) {
    // console.log( 'getCapitalizedString --> ', text.charAt(0).toUpperCase() + text.substring(1) );
    return text.charAt(0).toUpperCase() + text.substring(1);
  }

  getSChoolYearByNumber() {
    // console.log( 'schoolYear', this.data['schoolYear'] );
    if ( Number(this.data['schoolYear']) === 1 ) {
      return 'Primero';
    } else if ( Number(this.data['schoolYear']) === 2 ) {
      return 'Segundo';
    } else if ( Number(this.data['schoolYear']) === 3 ) {
      return 'Tercero';
    } else if ( Number(this.data['schoolYear']) === 4 ) {
      return 'Cuarto';
    } else if ( Number(this.data['schoolYear']) === 5 ) {
      return 'Quinto';
    } else if ( Number(this.data['schoolYear']) === 6 ) {
      return 'Sexto';
    }
  }

  getSChoolYearByText(text: string) {
    if ( text === 'Primero' ) {
      return 1;
    } else if ( text === 'Segundo' ) {
      return 2;
    } else if ( text === 'Tercero' ) {
      return 3;
    } else if ( text === 'Cuarto' ) {
      return 4;
    } else if ( text === 'Quinto' ) {
      return 5;
    } else if ( text === 'Sexto' ) {
      return 6;
    }
  }

  onSubmit() {
    this.isBusy = true;
    this.groupSentError = false;
    const schoolYearNumber = this.getSChoolYearByText(this.addGroupForm.value.schoolYear);

    this._groupApiService.saveGroup(
      this.groupId,
      this.userId,
      // code,
      this.addGroupForm.value.alias,
      schoolYearNumber,
      this.addGroupForm.value.schoolLevel.toLowerCase()
    ).subscribe( response => {
      this.groupSent = true;
      this.groupSentError = false;
      this.isBusy = false;
      if ( this.editingCanDeleteGroup ) { // editing group
        let editedGroupIndex = 0;
        this.groups.forEach( (group, index) => {
          if ( group.id === this.groupId ) {
            editedGroupIndex = index;
          }
        });
        this.groups[editedGroupIndex] = response.group;
        this._groupApiService.groups$.next(this.groups);
      } else { // creating group
        this.groups.push(response.group);
      }
    }, ( error ) => {
      console.log( error );
      this.groupSent = false;
      this.groupSentError = true;
      this.isBusy = false;
    });

  }

  onSchoolLevelsChange() {
    switch (this.addGroupForm.value.schoolLevel) {
        case 'Secundaria':
        case 'Preescolar':
        this.schoolYears = [
          'Primero',
          'Segundo',
          'Tercero'
        ];
        break;
      case 'Primaria':
        this.schoolYears = [
          'Primero',
          'Segundo',
          'Tercero',
          'Cuarto',
          'Quinto',
          'Sexto'
        ];
        break;

      default:
        break;
    }
    // console.log( 'onSchoolLevelsChange this.schoolYears --> ', this.schoolYears );
  }

  deleteWarning() {
    this.deleteStatus = 'confirming';
  }

  deleteCanceled() {
    this.deleteStatus = '';
  }

  deleteGroup() {
    this.isBusy = true;
    this._groupApiService.deleteGroup(this.groupId)
    .subscribe( response => {
      this.isBusy = false;
      this.deleteStatus = 'deleted';
      const restOfGroups = this.groups.filter( group => group.id !== this.groupId );
      this._groupApiService.groups$.next(restOfGroups);
    }, ( error ) => {
      console.log( error );
      this.isBusy = false;
      this.deleteStatus = 'error';
    });
  }

  subscribeToGroups() {
    this._groupApiService.groups$
    // .takeUntil(this.destroyed$)
    .subscribe( groups => {
      this.groups = groups;
    }, error => {
      console.log( error );
    });
  }

}
