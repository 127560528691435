import { environment } from 'src/environments/environment';
import { Injectable, ChangeDetectorRef } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Student , Group, Lesson } from '../../models';
import { BehaviorSubject } from 'rxjs';
// import { Performance } from '../../models/performance';

@Injectable({
  providedIn: 'root'
})
export class GroupApiService {
  selectedYear$ = new BehaviorSubject<string>('');
  selectedMonth$ = new BehaviorSubject<string>('');
  selectedSchoolYear$ = new BehaviorSubject<number>(1);
  selectedLesson$ = new BehaviorSubject<string>('');
  groups$ = new BehaviorSubject<Group[]>([]);
  selectedFormattedDate$ = new BehaviorSubject<string>('');
  // showReport$ = new BehaviorSubject<boolean>(true);
  selectedStudentId$ = new BehaviorSubject<number>(0);
  selectedDisplayStudent$ = new BehaviorSubject<any>({});
  displayStudentUpdated$ = new BehaviorSubject<boolean>(false);
  performanceViewing$ = new BehaviorSubject<string>('');
  matchSessionDate$ = new BehaviorSubject<string>('');
  lastGroupSessionDay$ = new BehaviorSubject<string>('');
  lastGroupSessionMonth$ = new BehaviorSubject<string>('');
  lastGroupSessionYear$ = new BehaviorSubject<string>('');
  studentLessons$ = new BehaviorSubject<Array<Lesson>>([]);

  constructor(
    private _http: HttpClient,
  ) { }

  getGroups() {
    return this._http.get(environment.API_URL + `user/dashboard`)
      .pipe(map((data: any) => ({
        groups: data.groups.map( (group: Group) => new Group().parse(group)),
        allSesions: data.all_sesions,
        allUsers: data.all_users
      })));
  }

  getGroup(groupId: number) {
    return this._http.get(environment.API_URL + `user/group/${groupId}`)
    .pipe(map((data: any) => ({
      group: new Group().parse(data.group)
    })));
  }

  getPasswords(groupId: number) {
    return this._http.get(environment.API_URL + `user/group/${groupId}/passwords`)
    .pipe(map((data: any) => ({
      group: new Group().parse(data.group)
    })));
  }

  getAxes() {
     return this._http.get(environment.API_URL + 'student/axes')
    .pipe(map((data: any) => ({
      axes: data.axes
    })));
  }

  getStudent(studentId: number, groupId: number) {
     return this._http.get(environment.API_URL + `student/` + studentId + `?group_id=${groupId}`)
    .pipe(map((data: any) => ({
      student: new Student().parse(data.student)
    })));
  }

  // getGroupPerformance(axis, groupId , date: string) {
  //   const params = [
  //     `axis=${axis}`,
  //     `group_id=${ groupId }`,
  //     `month=${ date }`
  //   ].join('&');

  //   return this._http.get(environment.API_URL + 'user/group/performance?' + params)
  //   .pipe(map((data: any) => ({
  //     performance: data.performance,
  //     students: data.students.map((student: Student) => new Student().parse(student))
  //   })));
  // }

  // getGroupPerformanceReport(axis, groupId, date: string, lang: string) {
  //   const params = [
  //     `axis=${axis}`,
  //     `group_id=${ groupId }`,
  //     `month=${ date }`,
  //     `lang=${lang !== '' ? lang : 'es'}`
  //   ].join('&');

  //   return this._http.get(environment.API_URL + 'user/group/performance/report?' + params)
  //   .pipe(map((data: any) => ({
  //     data: data
  //   })));
  // }

  getStudentPerformanceReport(
    schoolYear: number,
    lesson: string,
    date: string,
    groupId: number,
    studentId: number,
    axis: string,
    lang: string
    ) {
    let monthString = String(date);
    if ( monthString.length === 6 ) {
      monthString = [monthString.slice(0, 5), monthString.slice(5)].join('0');
    }
    const params = [
      `school_year=${ schoolYear }`,
      `lesson=${ lesson }`,
      `month=${ monthString }`,
      `group_id=${ groupId }`,
      `student_id=${ studentId }`,
      `axis=${ axis }`,
      `lang=${lang !== '' ? lang : 'es'}`
    ].join('&');

    return this._http.get(environment.API_URL + 'student/performance/report?' + params)
    .pipe(map((data: any) => ({
      data: data
    })));
  }

  getStudentPerformance(groupId: number, axis: string, studentId: number, month: string, lesson: string, schoolYear: number) {
    let monthString = String(month);
    if ( monthString.length === 6 ) {
      monthString = [monthString.slice(0, 5), monthString.slice(5)].join('0');
    }
    const params = [
      `axis=${ axis }`,
      `student_id=${ studentId }`,
      `group_id=${ groupId }`,
      `month=${ monthString }`,
      `lesson=${ lesson }`,
      `school_year=${ schoolYear }`
    ].join('&');

    return this._http.get(environment.API_URL + 'student/performance?' + params)
    .pipe(map((data: any) => ({
      performance: data.performance,
      national: data.national,
      group: data.group
    })));
  }


  getLessons(axis: string) {
    const params = [
      `axis=${ axis }`,
    ].join('&');

    return this._http.get(environment.API_URL + 'axis/lessons?' + params)
    .pipe(map((data: any) => ({
      lessons: data.lessons,
    })));
  }

  saveGroup(
    id: number,
    userId: number,
    // code: 	String,
    alias: String,
    schoolYear: number,
    schoolLevel: number
  ) {
    const params = {
      id: id,
      user_id: userId,
      // code: code,
      alias: alias,
      school_year: schoolYear,
      school_level: schoolLevel
    };
    return this._http.post(environment.API_URL + `user/group/${ id }`, params)
    .pipe(map((data) => ({
      group: new Group().parse(data['group'])
    })));
  }

  deleteGroup(groupId: number) {
    return this._http.delete(environment.API_URL + 'user/group/' + groupId)
    .pipe(map((data) => ({
      status: data
    })));
  }

  // ------------------------------ 2020-08 UPDATE -----------------------------

  getGroupPerformance(axis, groupId , filter: string, grade: number) {
    const params = [
      `axis=${axis}`,
      `group_id=${ groupId }`,
      `filter=${ filter || 'all' }`,
      `school_year=${ grade || 0 }`,
    ].join('&');

    return this._http.get(environment.API_URL + 'user/group/performance?' + params)
    .pipe(map((data: any) => ({
      performance: data.performance,
      students: data.students.map((student: Student) => new Student().parse(student))
    })));
  }

  getGroupPerformanceReport(axis, groupId, filter: string, lang: string, grade: number) {
    const params = [
      `axis=${axis}`,
      `group_id=${ groupId }`,
      `filter=${ filter }`,
      `lang=${lang !== '' ? lang : 'es'}`,
      `school_year=${ grade || 0 }`,
    ].join('&');

    return this._http.get(environment.API_URL + 'user/group/performance/report?' + params)
    .pipe(map((data: any) => ({
      data: data
    })));
  }

  editStudentDisplayName(
    id: number,
    displayName: string
  ) {
    const params = {
      display_name: displayName,
    };
    return this._http.post(environment.API_URL + `student/${ id }`, params)
    .pipe(map((data) => ({
      data: data
    })));
  }

  // ------------------------2021-03 UPDATE ------------------------------------
  hideStudent(id: number){
    const params = {
      display_name: "deactivated",
    };
    debugger;
    return this._http.post(environment.API_URL + `student/${ id }`, params)
    .pipe(map((data) => ({
      data: data
    })));
  }

}
