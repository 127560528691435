import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-help-modal',
  templateUrl: './help-modal.component.html',
  styleUrls: ['./help-modal.component.scss']
})
export class HelpModalComponent implements OnInit {
  helpType = '';

  constructor(
    public dialogRef: MatDialogRef<HelpModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ''
  ) { }

  ngOnInit() {
    // console.log(this.data);
    this.helpType = this.data;
  }

  closeModal() {
    this.dialogRef.close();
  }

  onSubmit() {
    // console.log('onSubmit download report');
    this.closeModal();
  }

}
