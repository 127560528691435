import * as moment from 'moment';
import { Student } from '../student/studet';
import { Session } from '../session';

export interface IBackendGroup {
  id?: number;
  user_id?: number;

  code?: string;
  alias?: string;
  school_year?: string;
  school_level?: string;
  percent?: number;
  students_count?: number;
  sessions_count?: number;
  missions_count?: number;
  students?: Student[];
  sessions?: Session[];
}

export interface IGroup {
  id?: number;
  userId?: number;

  code?: string;
  alias?: string;
  schoolYear?: string;
  schoolLevel?: string;
  percent?: number;
  studentsCount?: number;
  sessionsCount?: number;
  missionsCount?: number;
  students?: Student[];
  sessions?: Session[];
}

export class Group implements IGroup {
  static readonly clean = Object.freeze(new Group());
  id = 0;
  userId = 0;

  code = '';
  alias = '';
  schoolYear = '';
  schoolLevel = '';
  percent = 0;
  studentsCount = 0;
  missionsCount = 0;
  sessionsCount = 0;
  students: Student[];
  sessions: Session[];


  createdAt: Date;
  updatedAt: Date;


  parse(group: IBackendGroup) {
    this.id = Number(group.id || Group.clean.id);
    this.userId = Number(group.user_id || Group.clean.userId);

    this.code = String(group.code || Group.clean.code);
    this.alias = String(group.alias || Group.clean.alias);
    this.schoolYear = String(group.school_year || Group.clean.schoolYear);
    this.schoolLevel = String(group.school_level || Group.clean.schoolLevel);
    this.percent = Number(group.percent || Group.clean.percent);
    this.studentsCount = Number(group.students_count || Group.clean.studentsCount);
    this.sessionsCount = Number(group.sessions_count || Group.clean.sessionsCount);
    this.missionsCount = Number(group.missions_count || Group.clean.sessionsCount);
    if (group.students) {
      this.students = (group.students || Group.clean.students).map(student => new Student().parse(student));
    }
    if (group.sessions) {
      this.sessions = (group.sessions || Group.clean.sessions).map(session => new Session().parse(session));
    }

    return this;
  }

  set(group: IGroup) {
    Object.entries(this).forEach(([key]) => {
      this[key] = group[key] || this[key];
    });

    return this;
  }

  clone() {
    return new Group().set(this);
  }

  clear() {
    Object.entries(this).forEach(([key]) => {
      this[key] = Group.clean[key];
    });

    return this;
  }
}
