import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  userLangStored: string;
  userLang: any;
  availableLanguages: any;

  constructor(
    private translationService: TranslateService,
  ) {
    this.availableLanguages = ['es', 'en', 'de'];
    this.setupLang();
  }


  // Assings a Language
  setupLang() {
    let defaultLang = navigator.language.substr(0, 2);
    if (this.availableLanguages.indexOf(defaultLang) === -1) {
      defaultLang = 'es';
    }
    this.userLangStored = localStorage.getItem('userLangStored') || defaultLang; // Request and stores a Stored Language
    this.translationService.use(this.userLangStored);
    // console.log('stored lang ' + defaultLang);
  }

  // Function to assign a new language to the system
  setLanguage(languageCode: string) {
    this.translationService.use(languageCode); // Sets the new language to the service.
    localStorage.setItem('userLangStored', languageCode); // Stores the new language to the user's browser's local storage.
  }

}
