import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { UserApiService } from 'src/app/_core/api/user';

@Component({
  selector: 'app-password-modal',
  templateUrl: './password-modal.component.html',
  styleUrls: ['./password-modal.component.scss']
})
export class PasswordModalComponent implements OnInit {
  passwordForm = this._formBuilder.group({
    email: ['', [Validators.required, Validators.email]]
  });
  emailSentError = false;
  emailSent = false;
  emailValid = false;
  isBusy = false;

  constructor(
    private _userApiService: UserApiService,
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<PasswordModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ''
  ) { }

  ngOnInit() {
    // console.log(this.data);
  }

  closeModal() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.isBusy = true;
    this.emailSentError = false;
    this.emailValid = true;

    this._userApiService.forgotPassword(this.passwordForm.value.email)
    .subscribe(response => {
      // console.log( 'forgot password response --> ', response );
      if (response.status === 'success') {
        this.emailSent = true;
        this.emailSentError = false;
      }
      this.isBusy = false;
    }, (error) => {
      console.log(error);
      if (error.error.error === 'The selected email is invalid.') {
        this.emailSent = false;
        this.emailSentError = true;
        this.emailValid = true;
      } else {
        this.emailSent = false;
        this.emailSentError = true;
        this.emailValid = false;
      }
      this.isBusy = false;
    });
  }

}
