import * as moment from 'moment';

export interface IBackendUser {
  id?: number;
  organization_id?: number;

  privilege?: string;
  email?: string;
  full_name?: string;
  password?: string;
  organization_name?: string;
  logo_path?: string;
  lang?: string;
}

export interface IUser {
  id?: number;
  organizationId?: number;

  privilege?: string;
  email?: string;
  fullName?: string;
  password?: string;
  organizaionName?: string;
  logoPath?: string;
  lang?: string;
}

export class User implements IUser {
  static readonly clean = Object.freeze(new User());
  id = 0;
  organizationId = 0;

  privilege = '';
  email = '';
  fullName = '';
  password = '';
  organizaionName = '';
  logoPath = '';
  lang = '';

  createdAt: Date;
  updatedAt: Date;


  parse(user: IBackendUser) {
    this.id = Number(user.id || User.clean.id);
    this.organizationId = Number(user.organization_id || User.clean.organizationId);

    this.privilege = String(user.privilege || User.clean.privilege);
    this.email = String(user.email || User.clean.email);
    this.fullName = String(user.full_name || User.clean.fullName);
    this.password = String(user.password || User.clean.password);
    this.organizaionName = String(user.organization_name || User.clean.organizaionName);
    this.logoPath = String(user.logo_path || User.clean.logoPath);
    this.lang = String(user.lang || User.clean.lang);

    return this;
  }

  set(user: IUser) {
    Object.entries(this).forEach(([key]) => {
      this[key] = user[key] || this[key];
    });

    return this;
  }

  clone() {
    return new User().set(this);
  }

  clear() {
    Object.entries(this).forEach(([key]) => {
      this[key] = User.clean[key];
    });

    return this;
  }
}
