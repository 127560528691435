import { User } from './../../models';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private readonly _keys = {
    SESSION_TOKEN: 'VIKIDZ_SESSION_TOKEN',
    SESSION_USER: 'VIKIDZ_SESSION_USER',
  };

  private _user: User;
  private _sessionToken: string;

  constructor() {
  }

  get user() {
    if (this._user) {
      return this._user;
    }

    const encodedUser = localStorage.getItem(this._keys.SESSION_USER);
    if (encodedUser) {
      this._user = new User().set(JSON.parse(encodedUser));
      return this._user;
    }

    return null;
  }

  set user(value) {
    this._user = value;
    if (value != null) {
      localStorage.setItem(this._keys.SESSION_USER, JSON.stringify(this._user));
    } else {
      localStorage.removeItem(this._keys.SESSION_USER);
    }
  }

  get sessionToken() {
    if (this._sessionToken) {
      return this._sessionToken;
    }

    this._sessionToken = localStorage.getItem(this._keys.SESSION_TOKEN);

    return this._sessionToken;
  }

  set sessionToken(value) {
    this._sessionToken = value;
    if (value != null) {
      localStorage.setItem(this._keys.SESSION_TOKEN, value);
    } else {
      localStorage.removeItem(this._keys.SESSION_TOKEN);
    }
  }

  clear(): void {
    this.user = null;
    this.sessionToken = null;
  }

  isLogged() {
    return this.sessionToken && this.user && this.user.id > 0;
  }

}
