import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusPercentage'
})
export class StatusPercentagePipe implements PipeTransform {

  transform(value: any): any {

    if ( value === 0 ) {
      return 'Por Comenzar';
    } else if (value > 0 && value <= 75) {
      return 'En curso';
    } else if (value >= 76 && value < 100) {
      return 'Por terminar';
    } else if (value === 100) {
      return 'Terminado';
    }

    return value;
  }

}
