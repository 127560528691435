export interface IBackendSession {
  [prop: string]: unknown;
  // ^^^ because it was showing ERROR in src/app/_core/models/group/group.ts(71,99):
  // error TS2559: Type 'Session' has no properties in common with type 'IBackendSession'.
  // https://mariusschulz.com/blog/weak-type-detection-in-typescript
  session_date?: string;
  session_hash?: string;
}

export interface ISession {
  sessionDate?: string;
  sessionHash?: string;
}

export class Session implements ISession {
  [prop: string]: unknown;
  static readonly clean = Object.freeze(new Session());
  sessionDate = '';
  sessionHash = '';


  parse(session: IBackendSession) {
    this.sessionDate = String(session.session_date || Session.clean.sessionDate);
    this.sessionHash = String(session.session_hash || Session.clean.sessionHash);

    return this;
  }

  set(session: ISession) {
    Object.entries(this).forEach(([key]) => {
      this[key] = session[key] || this[key];
    });

    return this;
  }

  clone() {
    return new Session().set(this);
  }

  clear() {
    Object.entries(this).forEach(([key]) => {
      this[key] = Session.clean[key];
    });

    return this;
  }
}
